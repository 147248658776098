<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW NMAPI LOAN</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-layout row wrap class="align-center mx-2 ">
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2"
              v-model="date"
              label="Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>

        </v-layout>
        <v-layout row wrap class="align-center mx-2 ">
          <v-flex xs12 md12>
            <v-autocomplete
              class="mx-2"
              dense
              outlined
              v-model="search"
              :items="search_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="company_name"
              item-value="id"
              label="Search"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              @change="get_search_items_info"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="name"
              label="Name"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="address"
              label="Address"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="contact_no"
              label="Contact#"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-toolbar dense class="mt-9">
          <v-toolbar-title><h4 class="font-weight-light">LOAN DETAILS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="purpose"
              label="Purpose"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              v-if="selection==='Damayan'"
              class="mx-2"
              v-model="collateral"
              label="Collateral"
              dense
              outlined
              readonly
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="collateral"
              label="Collateral"
              dense
              outlined
              :rules="rules.default_max_100_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              v-if="selection==='Damayan'"
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              readonly
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
            <v-text-field
              v-else
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
              @change="calculation"
              @keyup="calculation"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-select
              v-model="terms"
              class="mx-2"
              dense
              outlined
              label="Terms (months)"
              :items="terms_items"
              @change="selected"
              @keyup="selected"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              v-model="interest"
              class="mx-2"
              dense
              outlined
              label="Interest (%)"
              :items="interest_items"
              @change="calculation"
              @keyup="calculation"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="total_interest"
              label="Interest Aquired"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="mx-2"
              v-model="total_formatted"
              label="Total Loan"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="monthly_due"
              label="Monthly Ammortization"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-toolbar dense class="mt-4">
              <v-toolbar-title><h4 class="font-weight-light">DEDUCTIONS DETAILS</h4>
              </v-toolbar-title>
            </v-toolbar>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2 mt-4"
              v-model="processing_fee"
              label="Processing Fee"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2 mt-4"
              v-model="loan_insurance_fee"
              label="Loan Insurance Fee"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2 mt-4"
              v-model="nmapi_fee"
              label="NMAPI Fee"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              class="mx-2 mt-4"
              v-model="equity_fee"
              label="Equity Fee"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="total_net_formatted"
              label="Total Net"
              dense
              outlined
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="co_maker"
              label="Co-maker Name"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <!-- alert -->
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="me-3 mt-4"
            @click="save_loan"
            v-if="!saving"
          >
            Save changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      selection: '',

      search: '',
      search_items: [],
      interest_items: [],
      terms_items: [],
      isLoading: false,

      name: '',
      contact_no: '',
      address: '',
      branch: '',
      collateral: '',
      co_maker: '',

      total: 0,
      nmapi_fee: 0,
      equity_fee: 0,
      total_net: 0,
      total_net_formatted: '0.00',
      total_formatted: '0.00',
      loan_insurance_fee: 0,
      total_interest: 0,
      processing_fee: 0,
      half_monthly_due: 0,
      monthly_due: 0,
      interest: 0,
      terms: '0 ',
      purpose: '',
      date: '',
      amount: 0,
      borrower_id: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('loan', ['create_loan',]),
      ...mapActions('members_information', ['search_members']),
      reset() {
        Object.assign(this.$data, initialState())
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected() {
        this.terms_items = ['6 months', '1 year', '2 years', '3 years', '4 years', '5 years']
        this.interest_items = ['2.5']
        this.interest = '2.5'
        this.calculation()
      },
      searching(value) {
        this.name = ''
        this.branch = ''
        this.borrower_id = 0

        this.isLoading = true
        this.search_members({
          search_word: value,
        })
          .then(response => {
            this.search_items = response.data
            this.isLoading = false
          })
      },
      get_search_items_info(value) {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.borrower_id = this.search_items[index].id
            this.contact_no = this.search_items[index].contact_no
            this.address = this.search_items[index].address
            this.name = this.search_items[index].company_name
            this.selected()
          } else {
            this.name = ''
            this.branch = ''
            this.borrower_id = 0
          }
        } else {
          this.name = ''
          this.branch = ''
          this.borrower_id = 0
        }
      },
      calculation() {
        var extracted_terms = this.terms.split(' ')
        var months_terms = 0;
        if (extracted_terms[1].includes('year')) {
          switch (extracted_terms[0]) {
            case '1':
              months_terms = 12
              break;
            case '2':
              months_terms = 24
              break;
            case '3':
              months_terms = 36
              break;
            case '4':
              months_terms = 48
              break;
            case '5':
              months_terms = 60
              break;
          }
        } else if (extracted_terms[1].includes('month')) {
          months_terms = 6
        }
        var to_iner = parseFloat(months_terms) * parseFloat(this.interest)
        this.total_interest = (parseFloat(this.amount) * (parseFloat(to_iner) / 100))
        this.processing_fee = (parseFloat(this.amount) * 0.03)
        this.equity_fee = (parseFloat(this.amount) * 0.02)
        this.total = parseFloat(this.amount) + parseFloat(this.total_interest)
        this.total_formatted = this.formatPrice(this.total)
        this.monthly_due = Math.ceil(parseFloat(this.total) / parseFloat(months_terms)).toFixed(2)
        this.nmapi_fee = (parseFloat(this.amount) * 0.02)

        var term_loan_insurance = months_terms
        if (term_loan_insurance <= 12) {
          term_loan_insurance = 12;
        }
        var company = ((this.total * 1.1) * term_loan_insurance) / 1000;
        if (company < 300) {
          company = 300
        }
        this.loan_insurance_fee = Math.ceil(company);

        this.total_net = parseFloat(this.amount) - (parseFloat(this.nmapi_fee) + parseFloat(this.processing_fee) + parseFloat(this.loan_insurance_fee) + parseFloat(this.equity_fee))
        this.total_net_formatted = this.formatPrice(this.total_net)
      },
      save_loan() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var extracted_terms = this.terms.split(' ')
          var months_terms = 0;
          if (extracted_terms[1].includes('year')) {
            switch (extracted_terms[0]) {
              case '1':
                months_terms = 12
                break;
              case '2':
                months_terms = 24
                break;
              case '3':
                months_terms = 36
                break;
              case '4':
                months_terms = 48
                break;
              case '5':
                months_terms = 60
                break;
            }
          } else if (extracted_terms[1].includes('month')) {
            months_terms = 6
          }
          const data = new FormData()
          data.append('date', this.date);
          data.append('co_maker', this.co_maker.toUpperCase());
          data.append('purpose', this.purpose.toUpperCase());
          data.append('collateral', this.collateral.toUpperCase());
          data.append('branch', this.branch);
          data.append('terms', months_terms);
          data.append('loan_amount', this.amount);
          data.append('interest', this.interest);
          data.append('total_interest', this.total_interest);
          data.append('total_loan', this.total);
          data.append('processing_fee', this.processing_fee);
          data.append('loan_insurance_fee', this.loan_insurance_fee);
          data.append('nmapi_fee', this.nmapi_fee);
          data.append('equity_fee', this.equity_fee);
          data.append('total_net', this.total_net);
          data.append('borrower_id', this.borrower_id);
          data.append('monthly_premium', this.monthly_due);
          this.create_loan(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
